import React from 'react';
import { graphql } from 'gatsby'

import Layout from '../../components/Layout';
import useMatchedQuery from '../../hooks/useMatchedQuery';

import ContentfulComponentBlock from '../../ContentfulComponents/ContentfulComponentBlock';


export const query = graphql`
query {
 

    contentfulComponentBlock0: contentfulComponentBlock (node_locale: {eq: "es-AR"}, contentful_id: {eq: "3PeU2hZGIbxJDiw0CEMKcR"}) {
      
id
name
contentful_id
node_locale
title
direction
cta {
  link
  label
  color
}
reveal {
  type
  props
}
image {
  gatsbyImageData(
    layout: FULL_WIDTH,
    placeholder: BLURRED,
    width: 500,
  )  
}
content {
  raw
}

      internal {
        type
      }    
    }
  



 
 
 site {
  siteMetadata {
    siteUrl
  }
}}

`

const Page = (props) => {
  const seo = props.pageContext.seo;
  const matchedQuery = useMatchedQuery()
  const { contentIds, ...context } = props.pageContext
  const { data } = props;
  return (
    <Layout {...context} matchedQuery={matchedQuery} useHeader={true} useContainer={true} >
      
      <ContentfulComponentBlock {...props} matchedQuery={matchedQuery} useHeader={true} useContainer={true} componentId="contentfulComponentBlock0" pathName="/contenido/Preguntas-Frecuentes" />
      
    </Layout>
  )
}

export default Page;
